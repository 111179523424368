<template>
 <div>
  <table>
  <thead>
    <tr>
      <th>Quantity Type</th>
      <th>Units</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Fulfillable</td>
      <td>{{ available.fulfillable }}</td>
    </tr>
    <tr>
      <td>Inbound Shipped</td>
      <td>{{ available.inbound }}</td>
    </tr>
    <tr>
      <td>Inbound Receiving</td>
      <td>{{ available.inboundReceiving }}</td>
    </tr>
    <tr>
      <td>Reserved</td>
      <td>{{ available.reserved }}</td>
    </tr>
    <tr>
      <td>unsellable</td>
      <td>{{ available.unsellable }}</td>
    </tr>
    <tr>
      <td><strong>Total</strong></td>
      <td><strong>{{ available.totalQuantity }}</strong></td>
    </tr>
    <br>
    <tr>
      <td>Sell Price</td>
      <td>$ {{ available.sellPrice }}</td>
    </tr>
    <br>
    <tr>
      <td>MFN Fulfillable</td>
      <td>{{ available.mfnFulfillable }}</td>
    </tr>
  </tbody>
</table>
  </div>
</template>

<script>

export default {
  props: {
    available: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  min-width: 250px;
  border-collapse: collapse;
}

thead {
  background-color: rgba(0, 0, 0, 0.1);
}

th {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

td {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

tr.total-row {
  background-color: #d9edf7;
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

</style>
